<template>
    <div>
        <br>
        <PostContainer :headless="true">
            <Banner src="/assets/img/camera.jpeg" :clipped="false" height="200px"/>
            <br>
            <v-alert v-if="success != null" :type="success ? 'success':'error'">
                <p class="white--text">{{ message }}</p>
            </v-alert>
            <v-form style="width: 100%;" ref="form">
                
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined dense
                        v-model.trim="authForm.email"
                        :rules="[v => !!v || `Email é obrigatório.`]"
                        type="email"
                        :counter="254"
                        label="Email"
                        data-vv-name="email"></v-text-field>
                </v-col>

                <v-col>
                    <v-btn
                        @click="submit"
                        color="primary" small
                        :disabled="loadingReset"
                        :loading="loadingReset">
                        <span class="black--text">Recuperar</span>
                        <template v-slot:loader>
                            <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                            </span>
                        </template>
                    </v-btn>
                </v-col>
            </v-form>
        </PostContainer>
        <br>
    </div>
</template>
<script>

import Banner from '../../components/Banner';
import PostContainer from '../../components/PostContainer';

import { mapActions, mapState } from 'vuex';

//import { PROFILE } from '../routes';

export default {
    
    data: () => ({
        authForm: {
            email: null,
        },
        success: null,
        message: null,
    }),
    computed: mapState('auth', ['loadingReset']),
    methods: {
        ...mapActions('auth', ['resetPassword']),
        submit() {
            this.error = null;
            this.message = null;

            if (this.$user.isAuthenticated) {
                this.error = 'Já se encontra autenticado.'
                return;
            }

            if (!this.$refs.form.validate()) return;
            this.resetPassword(this.authForm).then((state) => {
                this.success = state.success;
                this.message = state.message;

            }).catch(() => {
                this.error = 'Problema no pedido.';
            });
        },
        
    },
    components: {
        Banner,
        PostContainer,
    }
}
</script>